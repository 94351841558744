<template>
    <div>
        <header class="el-drawer__header nanw_khan">
            <span title="Новый заказ">{{
                $t("message.new_measurements")
            }}</span>
            <el-button
                type="success"
                size="small"
                class="mr-1"
                @click="fetchUpdate(true)"
                >{{ $t("message.save") }}</el-button
            >
            <el-button
                type="warning"
                @click="close()"
                icon="el-icon-close"
                size="small"
                >{{ $t("message.close") }}</el-button
            >
        </header>
        <div class="p-4">
            <el-form
                ref="form"
                status-icon
                v-loading="loading"
                :model="plan"
                :rules="rules"
                label-position="right"
                class="style__label"
                size="small"
            >
                <el-form-item>
                    <el-upload
                        ref="myBtnn"
                        class="upload-demo w-100"
                        action="/"
                        :limit="1"
                        :on-change="updateImageList"
                        :on-remove="handleRemove"
                        :on-progress="onProg"
                        :file-list="fileList"
                        list-type="picture"
                        :auto-upload="false"
                    >
                        <el-button
                            size="small"
                            type="primary"
                            class="w-100"
                            round
                        >
                            <i class="el-icon-upload"></i
                            >{{ $t("message.new_photo") }}
                        </el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item
                    :label="columns.address.title"
                    prop="address"
                    size="mini"
                >
                    <el-input
                        v-model="plan.address"
                        filterable
                        autocomplete="off"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item :label="columns.web.title" size="mini">
                    <el-input
                        v-model="plan.web"
                        filterable
                        autocomplete="off"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item :label="columns.email.title" size="mini">
                    <el-input
                        v-model="plan.email"
                        filterable
                        autocomplete="off"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item
                    :label="columns.number.title"
                    prop="number"
                    size="mini"
                >
                    <el-input
                        v-model="plan.number"
                        filterable
                        autocomplete="off"
                        clearable
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import printlogo from "@/utils/mixins/printlogo";
import drawerChild from "@/utils/mixins/drawer-child";
export default {
    props: ["selected"],
    mixins: [printlogo, drawerChild],

    data() {
        return {
            loading: false,
            plan: {},
        };
    },
    computed: {
        ...mapGetters({
            rules: "printlogos/rules",
            fileList: "printlogos/fileList",
        }),
    },
    methods: {
        ...mapActions({
            save: "printlogos/update",
            getModel: "printlogos/show",
            empty: "printlogos/empty",
            update: "printlogos/update",
            deleteImage: "printlogos/deleteImage",
        }),
        async fetchData() {
            this.$emit("fetch");
        },
        afterOpened() {
            this.handleUpdate(this.selected);
        },
        afterClosed() {
            this.handleUpdate(this.selected);
        },
        fetchEmpty() {
            this.empty();
        },
    },
};
</script>
