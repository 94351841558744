<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t('message.new_measurements') }}</span>
      <el-button
        type="success"
        size="small"
        class="mr-1"
        @click="submit(true)"
      >{{ $t('message.save') }}</el-button>
      <el-button type="warning" @click="close()" icon="el-icon-close" size="small">{{ $t('message.close') }}</el-button>
    </header>

    <div class="p-4">
        <el-form v-loading="loading" ref="form" :rules="rules"  status-icon :model="form"
            label-position="right" class="style__label" size="small">
            <el-form-item>
                <el-upload class="upload-demo w-100" action="/" :limit="1" :on-change="createImageList"  :file-list="fileList" list-type="picture" :auto-upload="false">
                  <el-button size="small" type="primary" class="w-100" round>
                      <i class="el-icon-upload"></i>{{ $t('message.new_photo') }}
                  </el-button>
                </el-upload>
            </el-form-item>
            <el-form-item :label="columns.address.title" prop="address" size="mini">
                <el-input v-model="form.address" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="columns.web.title"  size="mini">
                <el-input v-model="form.web" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="columns.email.title"  size="mini">
                <el-input v-model="form.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="columns.number.title" prop="number" size="mini">
                <el-input v-model="form.number" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
    </div>
  </div>
</template>
<script>
import {
    mapGetters,
    mapActions
} from 'vuex';
import form from '@/utils/mixins/form';
import printlogo from "@/utils/mixins/printlogo";
import drawerChild from '@/utils/mixins/drawer-child';
export default {
   mixins: [printlogo, form, drawerChild],
  data() {
      return {
          drawerOpened: false,
          loading: false,
          form: {},          
      };
  },

  computed: {
      ...mapGetters({
          rules: 'printlogos/rules',
          fileList: 'printlogos/fileList',
      })
  },
  methods: {
      ...mapActions({
          save: 'printlogos/store',
      }),
      fetchData() {
          this.$emit('fetchd')
      },
      afterOpened() {
          this.form = this.getForm;
      },
      afterClosed() {
      },
      waitingStop() {
          setTimeout(() => {
              this.waiting = false;
          }, 500);
      },

  }
};
</script>
